<template>
  <b-tabs
    ref="rc-view-tabbar"
    card
    pills
    fill
  >
    <b-tab>
      <template #title>
        <span>Linear Sections</span>
      </template>
      <div v-if="linear_sections.length > 0">
        <linear-sections
          :linear_sections="mapped_linear_sections"
          :city-list="cityList"
          :zone-list="zoneList"
          :agent-list="agentList"
        />
      </div>
      <div
        v-else
        class="d-flex justify-content-center align-items-center my-4 font-weight-bold"
      >
        This rate card has no linear sections
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>Bucket Sections</span>
      </template>
      <div v-if="bucket_sections.length > 0">
        <bucket-sections
          :bucket_sections="mapped_bucket_sections"
          :city-list="cityList"
          :zone-list="zoneList"
          :agent-list="agentList"
        />
      </div>
      <div
        v-else
        class="d-flex justify-content-center align-items-center my-4 font-weight-bold"
      >
        This rate card has no bucket sections
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>

/* eslint-disable vue/prop-name-casing */
/* eslint-disable vue/require-valid-default-prop */
import {
  BTabs,
  BTab,
} from 'bootstrap-vue'
import LinearSections from '@/components/rate-card/table-components/LinearSections.vue'
import BucketSections from '@/components/rate-card/table-components/BucketSections.vue'

export default {
  components: {
    BTabs,
    BTab,
    LinearSections,
    BucketSections,
  },
  props: {
    rate_card_type: {
      type: String,
      default: '',
    },
    linear_sections: {
      type: Array,
      default: [],
    },
    bucket_sections: {
      type: Array,
      default: [],
    },
    cityList: {
      type: Array,
      default: [],
    },
    agentList: {
      type: Array,
      default: [],
    },
    zoneList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      mapped_linear_sections: [],
      mapped_bucket_sections: [],
    }
  },
  async mounted() {
    this.linear_sections.forEach(element => {
      element.cities.forEach(city => {
        this.mapped_linear_sections.push({
          city,
          section_type: element.section_type,
          amount: element.amount,
        })
      })
    })
    this.bucket_sections.forEach(element => {
      element.cities.forEach(city => {
        this.mapped_bucket_sections.push({
          city,
          section_type: element.section_type,
          amount: element.amount,
        })
      })
    })
  },
}
</script>
