<template>
  <div>
    <b-table
      striped
      bordered
      hover
      responsive
      :items="bucket_sections"
      :fields="fields"
    >
      <template #cell(origin)="data">
        <origin-city-block
          :id="data.item.city.from_city"
          :type="data.item.city.from_type"
          :is-bidirectional="data.item.city.is_bidirectional"
          :city-list="cityList"
          :zone-list="zoneList"
          :agent-list="agentList"
        />
      </template>
      <template #cell(destination)="data">
        <destination-city-block
          :city-ids="data.item.city.to_cities"
          :type="data.item.city.to_type"
          :city-list="cityList"
          :zone-list="zoneList"
          :agent-list="agentList"
        />
      </template>
      <template #cell(amount.method)="data">
        <div
          class="font-weight-bold"
          style="text-transform: capitalize;"
        >
          {{ data.value.replaceAll('_', ' ') }}
        </div>
      </template>
      <template #cell(from_weight)="data">
        <hr>
        <div
          v-for="(weight, index) in data.item.amount.weights"
          :key="index"
        >
          <div style="margin-bottom:5px; margin-top:5px">
            {{ weight.from }}
          </div>
          <hr>
        </div>
      </template>
      <template #cell(to_weight)="data">
        <hr>
        <div
          v-for="(weight, index) in data.item.amount.weights"
          :key="index"
        >
          <div style="margin-bottom:5px; margin-top:5px">
            {{ weight.to }}
          </div>
          <hr>
        </div>
      </template>
      <template #cell(delivery_amount)="data">
        <hr>
        <div
          v-for="(weight, index) in data.item.amount.weights"
          :key="index"
        >
          <div style="margin-bottom:5px; margin-top:5px">
            {{ weight.delivery_amount }}
          </div>
          <hr>
        </div>
      </template>
      <template #cell(return_amount)="data">
        <hr>
        <div
          v-for="(weight, index) in data.item.amount.weights"
          :key="index"
        >
          <div style="margin-bottom:5px; margin-top:5px">
            {{ weight.return_amount }}
          </div>
          <hr>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

/* eslint-disable vue/prop-name-casing */
import {
  BTable,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import OriginCityBlock from '@core/components/rate-card/table-components/OriginCityBlock.vue'
import DestinationCityBlock from '@core/components/rate-card/table-components/DestinationCityBlock.vue'

export default {
  components: {
    BTable,
    OriginCityBlock,
    DestinationCityBlock,
  },
  mixins: [SuccessMessage, ErrorMessage, heightTransition],
  props: {
    bucket_sections: {
      type: Array,
      default() {
        return []
      },
    },
    cityList: {
      type: Array,
      default() {
        return []
      },
    },
    agentList: {
      type: Array,
      default() {
        return []
      },
    },
    zoneList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        { key: 'origin', label: 'Origin', sortable: true },
        { key: 'destination', label: 'Destination', sortable: false },
        { key: 'amount.method', label: 'Method', sortable: false },
        { key: 'from_weight', label: 'From Weight', sortable: false },
        { key: 'to_weight', label: 'To Weight', sortable: false },
        { key: 'delivery_amount', label: 'Delivery Amount', sortable: false },
        { key: 'return_amount', label: 'Return Amount', sortable: false },
      ],
    }
  },
}
</script>
