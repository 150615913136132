/* eslint-disable arrow-body-style */
<template>
  <b-overlay
    id="overlay-background"
    :show="dataLoading"
    variant="light"
    opacity="0.30"
    blur="10px"
    rounded="sm"
  >
    <div>
      <!-- Start Add Rate Card Button -->
      <div>
        <div>
          <b-row>
            <b-col md="3">
              <div class="form-label-group">
                <b-form-input
                  id="floating-label1"
                  v-model="rateCardName"
                  placeholder="Rate Card Name"
                  class="mt-2"
                  autocomplete="nope"
                  :disabled="readOnly"
                />
              </div>
            </b-col>
            <b-col
              v-if="readOnly && rateCardType !== 'walking-customer'"
              md="3"
            >
              <b-button
                v-can="`assign-${rateCardType === 'agent' ? 'agency' : rateCardType}-rate-card`"
                class="mt-2 custom-button-color"
                @click="onClickAssign()"
              >
                View/Assign {{ rateCardType.charAt(0).toUpperCase() + rateCardType.slice(1) }}
              </b-button>
            </b-col>
            <b-col
              v-if="is_clone"
              md="9"
              class="d-flex justify-content-end"
            >
              <b-form-checkbox
                v-model="user_clone_active"
                :checked="user_clone_active"
                name="user-clone-switch"
                inline
                class="mt-2"
              >
                <span
                  class="font-weight-bold"
                >
                  Clone Users
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col
              v-if="readOnly"
              md="6"
              class="d-flex justify-content-end"
            >
              <b-form-checkbox
                v-model="is_table_view"
                :checked="is_table_view"
                name="table-view-switch"
                switch
                inline
                class="mt-2"
              >
                <span
                  :class="is_table_view? 'font-weight-bold' : 'text-muted font-weight-bold' "
                >
                  Table View
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <br>
        </div>
      </div>
      <!-- End Add Rate Card Button -->
      <!-- Start Linear Rate Card Section -->
      <div v-if="is_table_view">
        <rate-card-table-view
          :rate_card_type="rateCardType"
          :linear_sections="linear_sections"
          :bucket_sections="bucket_sections"
          :city-list="CityList"
          :agent-list="AgentList"
          :zone-list="ZoneList"
        />
      </div>
      <div v-if="!is_table_view">
        <div>
          <b-card
            v-for="(section, section_index) in linear_sections"
            :key="section_index"
          >
            <div>
              <b-row>
                <b-col md="3">
                  <span class="font-weight-bold">Section #{{ section_index+1 }} - {{ section.section_type }} Rate Card #{{ section_index+1 }}</span>
                </b-col>
                <b-col md="3" />
                <b-col md="3" />
                <b-col md="1" />
                <b-col md="2">
                  <b-button
                    v-if="!readOnly"
                    v-ripple.400="'rgba(238, 114, 115)'"
                    v-b-tooltip.hover.v-danger.top="'Remove linear rate card'"
                    variant="danger"
                    class="mb-2 ml-md-3 ml-sm-0 btn-icon trash-btn"
                    @click="removeLinearSection(section)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-25"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <span
                    style="visibility: hidden"
                  >Spacing for 1 row</span>
                </b-col>
                <b-col md="3" />
              </b-row>
            </div>
            <div>
              <ValidationObserver
                ref="createCityForm"
                slim
              >
                <b-form
                  class="my-8 border border-dimgray rounded"
                >
                  <b-row class="mt-2 mr-1 ml-1">
                    <b-col
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Method"
                        label-for="v-name"
                      >
                        <v-select
                          v-model="section.amount.delivery_method"
                          :options="liearpricingMethod"
                          label="name"
                          :reduce="option => option.value"
                          placeholder="Select Method"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        v-b-tooltip.hover.top="'Delivery Charge Margin (kg)'"
                        label="Delivery Charge Margin"
                        label-for="v-del-ao"
                      >
                        <b-form-input
                          id="delivery_weight_margin"
                          v-model="section.amount.delivery_weight_margin"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Charge Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="delivery_weight_margin_rate"
                          v-model="section.amount.delivery_weight_margin_rate"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Charge After Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="delivery_weight_after_margin_rate"
                          v-model="section.amount.delivery_weight_after_margin_rate"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_order'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Charge"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="delivery_rate"
                          v-model="section.amount.delivery_rate"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1 mr-1 ml-1">
                    <b-col
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Method"
                        label-for="v-name"
                      >
                        <v-select
                          v-model="section.amount.return_method"
                          :options="liearpricingMethod"
                          label="name"
                          :reduce="option => option.value"
                          placeholder="Select Method"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        v-b-tooltip.hover.top="'Return Charge Margin (kg)'"
                        label="Return Charge Margin"
                        label-for="v-del-ao"
                      >
                        <b-form-input
                          id="return_weight_margin"
                          v-model="section.amount.return_weight_margin"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Charge Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="return_weight_margin_rate"
                          v-model="section.amount.return_weight_margin_rate"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Charge After Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="return_weight_after_margin_rate"
                          v-model="section.amount.return_weight_after_margin_rate"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_order'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Charge"
                        label-for="return_rate"
                      >
                        <b-form-input
                          id="return_rate"
                          v-model="section.amount.return_rate"
                          type="number"
                          autocomplete="nope"
                          :disabled="readOnly"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <span
                        style="visibility: hidden"
                      >Spacing for 1 row</span>
                    </b-col>
                    <b-col md="3" />
                  </b-row>
                </b-form>
                <b-row>
                  <b-col md="3">
                    <span
                      style="visibility: hidden"
                    >Spacing for 1 row</span>
                  </b-col>
                  <b-col md="3" />
                </b-row>
                <b-row>
                  <b-col cols="1" />
                  <b-col cols="11">
                    <div>
                      <div>

                        <!-- Row Loop -->
                        <b-row
                          v-for="(city, city_index) in section.cities"
                          :key="city_index"
                        >

                          <b-col md="4">
                            <b-form-group
                              label="Origin"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                :placeholder="rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                :disabled="readOnly"
                              />
                            </b-form-group>
                            <b-form-group
                              label="Origin City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_city"
                                :options="(city.from_type === 'city' )? CityList : (city.from_type === 'zone' )? ZoneList : (city.from_type === 'agent' )? AgentList : []"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                :placeholder="'Select ' + rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                :disabled="readOnly"
                              >
                                <template v-slot:option="option">
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="4">
                            <b-form-group
                              label="End"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                :placeholder="rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                :disabled="readOnly"
                              />
                            </b-form-group>
                            <b-form-group
                              label="Destination City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_cities"
                                :options="(city.to_type === 'city' )? CityList : (city.to_type === 'zone' )? ZoneList : (city.to_type === 'agent' )? AgentList : []"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                :placeholder="'Select ' + rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                multiple
                                deselect-from-dropdown
                                :style="readOnly ? 'background-color: #f0f0f0;':''"
                              >
                                <template
                                  v-slot:option="option"
                                >
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group
                              label=""
                              label-for="item-name"
                            >
                              <b-form-checkbox
                                v-model="city.is_bidirectional"
                                v-b-tooltip.hover.v-danger
                                title="Check this if only direction is Bidirectional"
                                class="ml-5 mt-4"
                                value="true"
                                :disabled="readOnly"
                              />
                              <span
                                class="ml-2"
                              >Bidirectional</span>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="!readOnly"
                            md="2"
                          >
                            <div class="d-flex align-items-center">
                              <b-button
                                v-ripple.400="'rgba(238, 114, 115)'"
                                v-b-tooltip.hover.v-danger.top="linear_sections[section_index].cities.length === 1 ? 'Sorry, cannot remove! Atleast one city section should be inserted.':'Remove cities section'"
                                variant="danger"
                                class="mt-0 mt-md-2 mb-2 ml-2 btn-icon"
                                @click="removeLinearSectionCities(section_index, city)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  class="mr-25"
                                />
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                v-b-tooltip.hover.v-success.top="'Add cities section'"
                                variant="success"
                                class="mt-0 mt-md-2 mb-2 ml-2 btn-icon"
                                @click="addLinearSectionCities(section_index)"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="mr-25"
                                />
                              </b-button>
                            </div>
                          </b-col>
                          <b-col md="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </div>
          </b-card>
        </div>
      </div>
      <!-- End Linear Rate Card Section -->
      <!-- Start Bucket Rate Card Section -->
      <div v-if="!is_table_view">
        <div>
          <b-card
            v-for="(section, section_index) in bucket_sections"
            :key="section_index"
          >
            <div>
              <b-row>
                <b-col md="4">
                  <span class="font-weight-bold">Section #{{ section_index+1 }} - {{ section.section_type }} Rate Card #{{ section_index+1 }}</span>
                </b-col>
                <b-col md="3" />
                <b-col md="3">
                  <v-select
                    v-model="section.amount.method"
                    :options="bucketPricingMethods"
                    :reduce="option => option.value"
                    label="name"
                    autocomplete="nope"
                    placeholder="Pricing Method"
                    :disabled="readOnly"
                  />
                </b-col>
                <b-col md="2">
                  <b-button
                    v-if="!readOnly"
                    v-ripple.400="'rgba(238, 114, 115)'"
                    v-b-tooltip.hover.v-danger.top="'Remove bucket rate card'"
                    variant="danger"
                    class="mb-2 ml-3 btn-icon"
                    @click="removeBucketSection(section)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-25"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <span
                    style="visibility: hidden"
                  >Spacing for 1 row</span>
                </b-col>
                <b-col md="3" />
              </b-row>
            </div>
            <div>
              <ValidationObserver
                ref="createCityForm"
                slim
              >
                <b-form
                  class="my-8 border border-dimgray rounded"
                >
                  <b-row>
                    <b-col md="3">
                      <span
                        style="visibility: hidden"
                      >Spacing for 1 row</span>
                    </b-col>
                    <b-col md="3" />
                  </b-row>
                  <b-row
                    v-for="(weight, weight_index) in section.amount.weights"
                    :key="weight_index"
                  >
                    <b-col
                      v-if="!readOnly"
                      md="1"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-tooltip.hover.v-success.top="'Add weights section'"
                        variant="success"
                        size="sm"
                        class="mt-0 mt-md-2 ml-2 btn-icon"
                        @click="addBucketSectionWeights(section_index)"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="readOnly"
                      cols="1"
                    />
                    <b-row class="mt-2 mr-1 ml-1">
                      <b-col
                        cols="3"
                        class="mobile-width-fix"
                      >
                        <b-form-group
                          label="From"
                          label-for="v-del-ao"
                        >
                          <b-form-input
                            id="v-del-ao"
                            v-model="weight.from"
                            type="number"
                            autocomplete="nope"
                            :disabled="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="3"
                        class="mobile-width-fix"
                      >
                        <b-form-group
                          label="To"
                          label-for="v-del-ao"
                        >
                          <b-form-input
                            id="v-del-ao"
                            v-model="weight.to"
                            type="number"
                            autocomplete="nope"
                            :disabled="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="3"
                        class="mobile-width-fix"
                      >
                        <b-form-group
                          label="Delivery Amount"
                          label-for="v-ret-ao"
                        >
                          <b-form-input
                            id="v-ret-ao"
                            v-model="weight.delivery_amount"
                            type="number"
                            autocomplete="nope"
                            :disabled="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="3"
                        class="mobile-width-fix"
                      >
                        <b-form-group
                          label="Return Amount"
                          label-for="v-ret-ao"
                        >
                          <b-form-input
                            id="v-ret-ao"
                            v-model="weight.return_amount"
                            type="number"
                            autocomplete="nope"
                            :disabled="readOnly"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-col
                      v-if="!readOnly"
                      cols="1"
                    >
                      <div>
                        <b-button
                          v-ripple.400="'rgba(238, 114, 115)'"
                          v-b-tooltip.hover.v-danger.top="bucket_sections[section_index].amount.weights.length === 1 ? 'Sorry, cannot remove! Atleast one weights section should be inserted.':'Remove weights section'"
                          variant="danger"
                          class="mt-0 mt-md-2 btn-icon"
                          size="sm"
                          @click="removeBucketSectionWeights(section_index, weight)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-25"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <span
                        style="visibility: hidden"
                      >Spacing for 1 row</span>
                    </b-col>
                    <b-col md="3" />
                  </b-row>
                </b-form>
                <b-row>
                  <b-col md="3">
                    <span
                      style="visibility: hidden"
                    >Spacing for 1 row</span>
                  </b-col>
                  <b-col md="3" />
                </b-row>
                <b-row>
                  <b-col cols="1" />
                  <b-col cols="11">
                    <div>
                      <div>
                        <!-- Row Loop -->
                        <b-row
                          v-for="(city, city_index) in section.cities"
                          :key="city_index"
                        >

                          <b-col md="4">
                            <b-form-group
                              label="Origin"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                :placeholder="rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                :disabled="readOnly"
                              />
                            </b-form-group>
                            <b-form-group
                              label="Origin City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_city"
                                :options="(city.from_type === 'city' )? CityList : (city.from_type === 'zone' )? ZoneList : (city.from_type === 'agent' )? AgentList : []"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                :placeholder="'Select ' + rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                :disabled="readOnly"
                              >
                                <template v-slot:option="option">
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="4">
                            <b-form-group
                              label="End"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                :placeholder="rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                :disabled="readOnly"
                              />
                            </b-form-group>
                            <b-form-group
                              label="Destination City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_cities"
                                :options="(city.to_type === 'city' )? CityList : (city.to_type === 'zone' )? ZoneList : (city.to_type === 'agent' )? AgentList : []"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                :placeholder="'Select ' + rateCardType === 'agent' ? 'City/Zone/Agent' : 'City/Zone'"
                                multiple
                                deselect-from-dropdown
                                :style="readOnly ? 'background-color: #f0f0f0':''"
                              >
                                <template
                                  v-slot:option="option"
                                >
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group
                              label=""
                              label-for="item-name"
                            >
                              <b-form-checkbox
                                v-model="city.is_bidirectional"
                                v-b-tooltip.hover.v-danger
                                title="Check this if only direction is Bidirectional"
                                class="ml-5 mt-4"
                                value="true"
                                :disabled="readOnly"
                              />
                              <span
                                class="ml-2"
                              >Bidirectional</span>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="!readOnly"
                            md="2"
                          >
                            <div class="d-flex align-items-center">
                              <b-button
                                v-ripple.400="'rgba(238, 114, 115)'"
                                v-b-tooltip.hover.v-danger.top="bucket_sections[section_index].cities.length === 1 ? 'Sorry, cannot remove! Atleast one city section should be inserted.':'Remove cities section'"
                                variant="danger"
                                class="mt-0 mt-md-2 mb-2 ml-2 btn-icon"
                                @click="removeBucketSectionCities(section_index, city)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  class="mr-25"
                                />
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                v-b-tooltip.hover.v-success.top="'Add cities section'"
                                variant="success"
                                class="mt-0 mt-md-2 mb-2 ml-2 btn-icon"
                                @click="addBucketSectionCities(section_index)"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="mr-25"
                                />
                              </b-button>
                            </div>
                          </b-col>
                          <b-col md="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </div>
          </b-card>
        </div>
      </div>
      <div
        v-if="!readOnly"
      >
        <b-row>
          <b-col md="6" />
          <b-col md="3">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="outline-success"
                @click="addLinearSection"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                Add Linear Rate Section
              </b-button>
            </div>
          </b-col>

          <b-col md="3">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                variant="outline-primary"
                @click="addBucketSection()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                Add Bucket Rate Section
              </b-button>
            </div>
          </b-col>
        </b-row>
        <br>
      </div>
      <!-- End Add Rate Card Button -->
      <!-- Start Submit Button -->
      <div
        v-if="!readOnly"
      >
        <b-row>
          <b-col md="6" />
          <b-col md="3">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="success"
                @click="showConfirmationBox"
              >
                Proceed
              </b-button>
            </div>
          </b-col>
          <b-col md="3">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                variant="outline-primary"
              >
                Clear All
              </b-button>
            </div>
          </b-col>
        </b-row>
      <!--      </b-card>-->
      </div>
      <assign-agent ref="assign_agent_rate_card" />
      <assign-rider ref="assign_rider_rate_card" />
      <assign-merchant ref="assign_merchant_rate_card" />
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  VBModal,
  BFormInput,
  BButton,
  BOverlay,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import AssignAgent from '@/components/rate-card/assign-models/AssignAgent.vue'
import AssignRider from '@/components/rate-card/assign-models/AssignRider.vue'
import AssignMerchant from '@/components/rate-card/assign-models/AssignMerchant.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import RateCardTableView from './rateCardTableView.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const ResourceRateCardRepository = RepositoryFactory.get('ratecard')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCol,
    BFormGroup,
    BForm,
    BOverlay,
    BRow,
    vSelect,
    BFormInput,
    BCard,
    BButton,
    BFormCheckbox,
    AssignAgent,
    AssignRider,
    AssignMerchant,
    RateCardTableView,
  },
  mixins: [SuccessMessage, ErrorMessage, heightTransition],
  data() {
    return {
      loading: false,
      dataLoading: false,
      form: {},
      rateCardName: '',
      originMethods: ['city', 'zone'],
      liearpricingMethod: [
        { name: 'Per Kg', value: 'per_kg' },
        { name: 'Per Order', value: 'per_order' },
      ],
      bucketPricingMethods: [
        { name: 'Per Kg', value: 'per_kg' },
      ],
      CityList: [],
      ZoneList: [],
      AgentList: [],
      // Start Section
      linear_sections: [
        {
          section_type: 'linear',
          amount: {
            delivery_method: 'per_kg',
            delivery_weight_margin: '0',
            delivery_weight_margin_rate: '0',
            delivery_weight_after_margin_rate: '0',
            return_method: 'per_kg',
            return_weight_margin: '0',
            return_weight_margin_rate: '0',
            return_weight_after_margin_rate: '0',
          },
          cities: [
            {
              from_type: '',
              from_city: '',
              to_type: '',
              to_city: '',
              to_cities: [],
              is_bidirectional: '',
            },
          ],
        },
      ],
      bucket_sections: [],
      // End Section
      direction: ['Bidirectional', 'Unidirectional'],
      rateCardType: null,
      id: null,
      readOnly: false,
      is_table_view: false,
      is_clone: false,
      is_update: false,
      user_clone_active: false,
    }
  },
  watch: {
    is_table_view(val) {
      if (!val) {
        this.setDropdownStyles()
      }
    },
  },
  created() {
    this.id = this.$route.params.id
    this.rateCardType = this.$route.params.rateCardType
    this.$route.meta.breadcrumb[0].text = `${this.rateCardType === 'walking-customer' ? 'Walking Customer' : this.rateCardType.charAt(0).toUpperCase() + this.rateCardType.slice(1)} Rate Card`
    this.$router.replace({ query: { temp: Date.now() } })
    this.$router.replace({ query: { temp: undefined } })
  },
  async mounted() {
    this.dataLoading = true
    if (window.location.href.includes('view')) {
      this.readOnly = true
    }
    await this.readDataforCity()
    await this.readDataforZone()
    if (this.rateCardType === 'agent') {
      await this.readDataforAgent()
      await this.originMethods.push('agent')
    }
    if (window.location.href.includes('clone')) this.is_clone = true
    if (this.is_clone || this.readOnly) await this.fetchRateCardDetails()
    if (window.location.href.includes('update')) {
      this.is_update = true
      await this.fetchRateCardDetails()
    }
    this.setDropdownStyles()
    this.dataLoading = false
  },
  methods: {
    addLinearSection() {
      const object = {
        section_type: 'linear',
        amount: {
          delivery_method: 'per_kg',
          delivery_weight_margin: '0',
          delivery_weight_margin_rate: '0',
          delivery_weight_after_margin_rate: '0',
          return_method: 'per_kg',
          return_weight_margin: '0',
          return_weight_margin_rate: '0',
          return_weight_after_margin_rate: '0',
        },
        cities: [
          {
            from_type: '',
            from_city: '',
            to_type: '',
            to_city: '',
            to_cities: [],
            is_bidirectional: '',
          },
        ],
      }
      this.linear_sections.push(object)
    },
    removeLinearSection(section) {
      const i = this.linear_sections.indexOf(section)
      if (i > -1) {
        this.linear_sections.splice(i, 1)
      }
    },
    addLinearSectionCities(index) {
      const object = {
        from_type: '',
        from_city: '',
        to_type: '',
        to_city: '',
        to_cities: [],
        is_bidirectional: '',
      }
      this.linear_sections[index].cities.push(object)
    },
    removeLinearSectionCities(index, city) {
      const i = this.linear_sections[index].cities.indexOf(city)
      if (i > -1 && this.linear_sections[index].cities.length !== 1) {
        this.linear_sections[index].cities.splice(i, 1)
      }
    },
    addBucketSection() {
      const object = {
        section_type: 'bucket',
        amount: {
          method: 'per_kg',
          weights: [
            {
              from: '0',
              to: '0',
              delivery_amount: '0',
              return_amount: '0',
            },
          ],
        },
        cities: [
          {
            from_type: '',
            from_city: '',
            to_type: '',
            to_city: '',
            to_cities: [],
            is_bidirectional: '',
          },
        ],
      }
      this.bucket_sections.push(object)
    },
    removeBucketSection(section) {
      const i = this.bucket_sections.indexOf(section)
      if (i > -1) {
        this.bucket_sections.splice(i, 1)
      }
    },
    addBucketSectionCities(index) {
      const object = {
        from_type: '',
        from_city: '',
        to_type: '',
        to_city: '',
        to_cities: [],
        is_bidirectional: '',
      }
      this.bucket_sections[index].cities.push(object)
    },
    removeBucketSectionCities(index, city) {
      const i = this.bucket_sections[index].cities.indexOf(city)
      if (i > -1 && this.bucket_sections[index].cities.length !== 1) {
        this.bucket_sections[index].cities.splice(i, 1)
      }
    },
    addBucketSectionWeights(index) {
      const object = {
        from: '0',
        to: '0',
        delivery_amount: '0',
        return_amount: '0',
      }
      this.bucket_sections[index].amount.weights.push(object)
    },
    removeBucketSectionWeights(index, weight) {
      const i = this.bucket_sections[index].amount.weights.indexOf(weight)
      if (i > -1 && this.bucket_sections[index].amount.weights.length !== 1) {
        this.bucket_sections[index].amount.weights.splice(i, 1)
      }
    },
    async readDataforCity() {
      try {
        this.CityList = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter()).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.CityList))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforZone() {
      try {
        this.ZoneList = localStorage.getItem('zone_list') ? JSON.parse(localStorage.getItem('zone_list')) : (await ResourceRepository.getZoneListDropdown()).data.data
        if (!localStorage.getItem('zone_list')) localStorage.setItem('zone_list', JSON.stringify(this.ZoneList))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforAgent() {
      try {
        this.AgentList = localStorage.getItem('agent_list') ? JSON.parse(localStorage.getItem('agent_list')) : (await ResourceRepository.getAgencyListDropdown()).data.data
        if (!localStorage.getItem('agent_list')) localStorage.setItem('agent_list', JSON.stringify(this.AgentList))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchRateCardDetails() {
      try {
        let data
        if (this.rateCardType === 'rider') {
          data = (await ResourceRateCardRepository.getRiderRateCardDetails(this.id)).data
        } else if (this.rateCardType === 'agent') {
          data = (await ResourceRateCardRepository.getAgentRateCardDetails(this.id)).data
        } else if (this.rateCardType === 'merchant') {
          data = (await ResourceRateCardRepository.getRateCardDetails(this.id)).data
        } else {
          data = (await ResourceRateCardRepository.getWalkingCustomerRateCardDetails(this.id)).data
        }
        if (this.readOnly || this.is_update) {
          this.rateCardName = data.data.card_name
        }
        this.rows = data.data
        this.linear_sections = this.rows.form_data.filter(n => n.section_type === 'linear')
        this.linear_sections.forEach((n, indexn) => {
          n.cities.map(async (m, indexm) => {
            const mappedCities = []
            if (m.from_type === 'city') {
              this.linear_sections[indexn].cities[indexm].from_type = 'city'
            }
            if (m.from_type === 'zone') {
              this.linear_sections[indexn].cities[indexm].from_type = 'zone'
            }
            if (m.from_type === 'Agent') {
              this.linear_sections[indexn].cities[indexm].from_type = 'Agent'
            }
            if (m.to_type === 'city') {
              this.linear_sections[indexn].cities[indexm].to_type = 'city'
            }
            if (m.to_type === 'zone') {
              this.linear_sections[indexn].cities[indexm].to_type = 'zone'
            }
            if (m.to_type === 'Agent') {
              this.linear_sections[indexn].cities[indexm].to_type = 'Agent'
            }

            const alikeCities = await n.cities.filter(c => c.from_city === m.from_city && c.from_type === m.from_type && c.to_type === m.to_type && c.is_bidirectional === m.is_bidirectional)
            alikeCities.forEach((c, index) => {
              mappedCities.push(c.to_city)
              // eslint-disable-next-line no-param-reassign
              if (index !== 0) n.cities = n.cities.filter(item => item !== c)
            })
            // eslint-disable-next-line no-param-reassign
            m.to_cities = mappedCities
          })
        })
        this.bucket_sections = this.rows.form_data.filter(n => n.section_type === 'bucket')
        this.bucket_sections.forEach((n, indexn) => {
          n.cities.map(async (m, indexm) => {
            const mappedCities = []
            if (m.from_type === 'city') {
              this.bucket_sections[indexn].cities[indexm].from_type = 'city'
            }
            if (m.from_type === 'zone') {
              this.bucket_sections[indexn].cities[indexm].from_type = 'zone'
            }
            if (m.from_type === 'agent') {
              this.bucket_sections[indexn].cities[indexm].from_type = 'agent'
            }
            if (m.to_type === 'city') {
              this.bucket_sections[indexn].cities[indexm].to_type = 'city'
            }
            if (m.to_type === 'zone') {
              this.bucket_sections[indexn].cities[indexm].to_type = 'zone'
            }
            if (m.to_type === 'agent') {
              this.bucket_sections[indexn].cities[indexm].to_type = 'agent'
            }
            const alikeCities = await n.cities.filter(c => c.from_city === m.from_city && c.from_type === m.from_type && c.to_type === m.to_type && c.is_bidirectional === m.is_bidirectional)
            alikeCities.forEach((c, index) => {
              mappedCities.push(c.to_city)
              // eslint-disable-next-line no-param-reassign
              if (index !== 0) n.cities = n.cities.filter(item => item !== c)
            })
            // eslint-disable-next-line no-param-reassign
            m.to_cities = mappedCities
          })
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onSubmit() {
      if (this.is_update) {
        this.rateCardName = this.rateCardName.replaceAll('Rate Card - ', '')
      }
      this.linear_sections.forEach((n, index) => {
        const { amount } = n
        // eslint-disable-next-line camelcase
        const new_amount = {}
        if (amount.delivery_method === 'per_kg') {
          new_amount.delivery_method = 'per_kg'
          new_amount.delivery_weight_margin = amount.delivery_weight_margin
          new_amount.delivery_weight_margin_rate = amount.delivery_weight_margin_rate
          new_amount.delivery_weight_after_margin_rate = amount.delivery_weight_after_margin_rate
        }
        if (amount.return_method === 'per_kg') {
          new_amount.return_method = 'per_kg'
          new_amount.return_weight_margin = amount.return_weight_margin
          new_amount.return_weight_margin_rate = amount.return_weight_margin_rate
          new_amount.return_weight_after_margin_rate = amount.return_weight_after_margin_rate
        }
        if (amount.delivery_method === 'per_order') {
          new_amount.delivery_method = 'per_order'
          new_amount.delivery_rate = amount.delivery_rate
        }
        if (amount.return_method === 'per_order') {
          new_amount.return_method = 'per_order'
          new_amount.return_rate = amount.return_rate
        }
        // eslint-disable-next-line camelcase
        this.linear_sections[index].amount = new_amount
      })

      if (this.rateCardName === '' || this.rateCardName === null) {
        this.showErrorMessage('Please Enter an Rate Card Name')
      } else if (this.bucket_sections.length === 0) {
        // eslint-disable-next-line arrow-body-style
        const linearSections = this.linear_sections.map(n => {
          const mappedCities = []
          // eslint-disable-next-line arrow-body-style
          n.cities.forEach(m => {
            m.to_cities.forEach(o => {
              mappedCities.push({
                from_type: m.from_type,
                from_city: m.from_city,
                to_type: m.to_type,
                to_city: o,
                is_bidirectional: m.is_bidirectional,
              })
            })
          })
          return {
            section_type: n.section_type,
            amount: n.amount,
            cities: mappedCities,
          }
        })
        const all = linearSections
        const rateCard = {
          rate_card_general_details: {
            card_name: `Rate Card - ${this.rateCardName}`,
          },
          sections: all,
        }
        this.handleSubmit(rateCard)
      } else {
        // eslint-disable-next-line arrow-body-style
        const linearSections = this.linear_sections.map(n => {
          const mappedCities = []
          // eslint-disable-next-line arrow-body-style
          n.cities.forEach(m => {
            m.to_cities.forEach(o => {
              mappedCities.push({
                from_type: m.from_type,
                from_city: m.from_city,
                to_type: m.to_type,
                to_city: o,
                is_bidirectional: m.is_bidirectional,
              })
            })
          })
          return {
            section_type: n.section_type,
            amount: n.amount,
            cities: mappedCities,
          }
        })
        const bucketSections = this.bucket_sections.map(n => {
          const mappedCities = []
          n.cities.forEach(m => {
            m.to_cities.forEach(o => {
              mappedCities.push({
                from_type: m.from_type,
                from_city: m.from_city,
                to_type: m.to_type,
                to_city: o,
                is_bidirectional: m.is_bidirectional,
              })
            })
          })
          return {
            section_type: n.section_type,
            amount: n.amount,
            cities: mappedCities,
          }
        })
        const all = linearSections.concat(bucketSections)

        const rateCard = {
          rate_card_general_details: {
            card_name: `Rate Card - ${this.rateCardName}`,
          },
          sections: all,
        }
        this.handleSubmit(rateCard)
      }
    },
    async handleSubmit(rateCard) {
      this.dataLoading = true
      try {
        if (this.is_update) {
          // eslint-disable-next-line no-param-reassign
          rateCard.is_update = this.is_update
          // eslint-disable-next-line no-param-reassign
          rateCard.current_rate_card_id = this.id
        }

        // eslint-disable-next-line no-param-reassign
        rateCard.user_clone_active = this.user_clone_active

        let res
        if (this.rateCardType === 'rider') {
          res = await ResourceRateCardRepository.createRiderRateCard(rateCard)
        } else if (this.rateCardType === 'agent') {
          res = await ResourceRateCardRepository.createAgentRateCard(rateCard)
        } else if (this.rateCardType === 'merchant') {
          res = await ResourceRateCardRepository.createRateCard(rateCard)
        } else {
          res = await ResourceRateCardRepository.createWalkingCustomerRateCard(rateCard)
        }
        if (res.status === 201) {
          if (this.user_clone_active) this.assignUsers(res.data.id)
          this.showSuccessMessage('Rate Card Added Successfully')
          this.$router.push(`/rate-card/${this.rateCardType.toLowerCase()}`)
        } else {
          this.showErrorMessage('Cannot Add Rate Card')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async assignUsers(id) {
      try {
        let userList
        const payload = {}
        switch (this.rateCardType) {
          case 'rider':
            userList = (await ResourceRateCardRepository.getRiderListRateCard(this.id, this.user_clone_active)).data.data
            payload.rider_ids = userList.map(n => n.id)
            ResourceRateCardRepository.assignRiderList(id, payload)
            break
          case 'agent':
            userList = (await ResourceRateCardRepository.getAssignedAgentRateCardList(this.id, this.user_clone_active)).data.data
            payload.agent_ids = userList.map(n => n.id)
            ResourceRateCardRepository.assignAgentList(id, payload)
            break
          case 'merchant':
            userList = (await ResourceRateCardRepository.getRateCardMerchantList(this.id, this.user_clone_active)).data.data
            payload.merchant_ids = userList.map(n => n.id)
            ResourceRateCardRepository.assignMerchantList(id, payload)
            break
          default:
            this.showErrorMessage('Invalid Rate Card Type')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    showConfirmationBox() {
      this.$bvModal
        .msgBoxConfirm(
          'This action is irreversible. You cannot change this rates after submitting this. If you want to '
              + 'change the rates, you have to deactivate the rate card and create a new one.', {
            title: 'Warning!!!',
            size: 'lg',
            okVariant: 'danger',
            okTitle: 'Submit',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.onSubmit()
          }
        })
    },
    onClickAssign() {
      if (this.rateCardType === 'rider') {
        this.$refs.assign_rider_rate_card.openModal(this.id)
      } else if (this.rateCardType === 'agent') {
        this.$refs.assign_agent_rate_card.openModal(this.id)
      } else if (this.rateCardType === 'merchant') {
        this.$refs.assign_merchant_rate_card.openModal(this.id)
      }
    },
    setDropdownStyles() {
      this.$nextTick(() => {
        if (this.readOnly) {
          document.querySelectorAll('.vs__selected-options , .vs__actions').forEach(n => {
            // eslint-disable-next-line no-unused-vars
            n.addEventListener('click', e => {
              const dropdownElements = document.querySelectorAll('.vs__dropdown-option')
              dropdownElements.forEach(item => {
                // eslint-disable-next-line no-param-reassign
                item.style.pointerEvents = 'none'
              })
            })
          })
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';

:root {
--bucketcolor: #6f57e8
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
@media (max-width: 767px) {
  .mobile-width-fix {
    max-width: 100%;
    flex-basis: 100%;
  }
  .trash-btn {
    margin-top: 30px;
  }
}
</style>
